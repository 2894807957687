<template>
  <div>
    <div class="top-header">
      <div class="top-left">
        <div class="logo">
          <img src="/img/CC-Logo-White.1144d714.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="mt-5" style="text-align: center">
      <div v-if="Response == 'Success'">
        <div>
          <h3>Registration completed successfully</h3>
        </div>
        <br>
        <h5>Login details to access your cubicle have been emailed to you.</h5>
        <br />
        <br />
      </div>
      <div v-if="Response == 'Exists'">
        <div>
          <h3>User already exists</h3>
        </div>
        <h5>For login page <a href="/signin">Click here</a></h5>
        <br />
        <br />
      </div>
      <div v-if="Response == 'Fail'">
        <div>
          <h3>Registration failed</h3>
        </div>
        <h5>Something went wrong please try again</h5>
        <br />
        <br />
      </div>
      <h6 v-if="Response !=''">
        In case you have any questions or clarifications, you can email
        <a href="mailto:contact@careercubicle.com">contact@careercubicle.com</a>
      </h6>
      <div v-if="showloader">
        <div
          class="processing_popup upload full-spinner"
          style="visibility: visible"
        >
          <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import rest_service from "@/api/rest_service_api.js";
import axios from "axios";
import { fas } from "@fortawesome/free-solid-svg-icons";
export default {
  created() {
    debugger;
    this.showloader=true;
    var email = this.$route.query.email;
    if (email != null && email != "") {
      this.Registration(email);
    }
  },
  data() {
    return {
      showloader: false,
      Response: "",
    };
  },
  methods: {
    Registration(email) {
      var self = this;
      axios({
        method: "post",
        url: rest_service.baseURL + "/Authenticate/registerv2",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: {
          email: email,
        },
      })
        .then(function (response) {
          if (response.data.result) {
            self.showloader=false;
            if (response.data.result == "Success") {
              self.Response = "Success";
            } else if (response.data.result == "User already exists!") {
              self.Response = "Exists";
            } else if (response.data.result == "User creation failed!") {
              self.Response = "Fail";
            }
          } else {
            self.showloader=false;
            return null;
          }
        })
        .catch(function (error) {
          self.showloader=false;
          console.log(error);
          return error;
        });
    },
  },
};
</script>
